<template>

  <div class="auth-wrapper auth-v2">
     <b-spinner style="width: 50px; height: 50px" label=""  class="spinner"  v-if="isSpinner"/>
    <b-row class="auth-inner m-0" v-if="!isSpinner">

      <!-- Brand logo-->
       <b-link
              class="brand-logo"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  src="@/assets/images/newLogo.png"
                  alt="logo"
                />
              </span>
        </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Reset Password 🔒
          </b-card-title>       
          
          <!-- form -->
          <div v-if="!showResend">
            <validation-observer ref="simpleRules">
              <b-form
                class="auth-reset-password-form mt-2"
                method="POST"
                @submit.prevent="validationForm"
              >

                <!-- password -->
                <b-form-group
                  label="Enter Password"
                  label-for="reset-password-new"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="Password"
                    rules="required|password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :type="password1FieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  label-for="reset-password-confirm"
                  label="Confirm Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="confirm password"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-confirm"
                        v-model="cPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="reset-password-confirm"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  Set New Password
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <!-- submit button -->
              <div v-if="showResend">
                <p>
                   This link is expired please click on below button to resend email
                </p>
                <b-button
                  block
                  type="submit"
                  variant="primary"
                  @click="ResendEmail"
                >
                 Resend Email
                </b-button>
              </div>
          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login-v2'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";
import firebase from "@/utils/firebaseInit";
const db = firebase.firestore()
import {dbCollections} from "@/utils/firebaseCollections";
export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,
      showResend:false,
      id:"",
      email: '',
      isSpinner: true,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created(){
      db.collection(dbCollections.USERS).doc(this.$route.params.id).get().then((resp)=>{
        if(resp.empty) {
          console.error("No Such User Found for User Id")
          this.isSpinner = false;
          return;
        }
        this.id= resp.data().id
        this.email = resp.data().email
        const tokenTime = new Date(resp.data().forgotPasswordTokenTime.seconds*1000);
        const ValidTime = new Date(tokenTime.setMinutes(tokenTime.getMinutes() + 10));
        if (!((resp.data().forgotPasswordToken == this.$route.params.token) && (ValidTime.getTime() > new Date().getTime()))) {
          this.showResend = true;
          this.isSpinner = false;
        } else {
          this.showResend = false;
          this.isSpinner = false;
        }
      }).catch((error) =>{
        console.error(error);
        this.isSpinner = false;
      })
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    ResendEmail(){
        this.isSpinner = true;
        axios.post(process.env.VUE_APP_APIURL + '/api/v1/sendForgotPasswordEmail', {
        // axios.post("http://localhost:4000" + '/api/v1/sendForgotPasswordEmail', {
          uid: this.$route.params.id,
          email: this.email,
        }).then((apiRes) => {
            if(apiRes.data.status){
                this.$toast.success("Email send successfully.")
                this.$router.push({name: 'auth-login'});
                this.isSpinner = false;
            } else {
                console.error(apiRes.data.statusText);
                this.isSpinner = false;
            }
        })
        .catch((error) =>{
            console.error(error);
            this.isSpinner = false;
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isSpinner = true;
          axios.post(process.env.VUE_APP_APIURL + '/api/v1/setNewPassword', {
          // axios.post("http://localhost:4000" + '/api/v1/setNewPassword', {
            uid: this.$route.params.id,
            newPassword: this.password,
          })
          .then((apiRes) => {
            if(apiRes.data.status){
                db.collection(dbCollections.USERS).doc(this.$route.params.id).update({forgotPasswordToken: ""}).then(()=>{
                // this.$toast.success("Password reset successfully.")
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Password reset successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({name: 'auth-login'});
                this.isSpinner = false;
                }).catch((error)=>{
                  console.error(error);
                  this.isSpinner = false;
                })
            } else {
                console.error("NOT DONE",apiRes.data.statusText);
                this.isSpinner = false;
            }
          })
          .catch((error) =>{
              console.error(error);
              this.isSpinner = false;
          })
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>
